import { Link, useNavigate } from "react-router-dom";
import { CheckSkeleton } from "../../SharedModule/utils/checkSkeleton";
import {
  ALL_PROJ_WO,
  ALL_PROJECTS_AND_WORK_ORDERS,
  ALL_WO_PROJ,
  APPROVED_TEXT,
  BILLABLE,
  LOCKED,
  nonBillableIds,
  PROJ_UNPAID_TIME_OFF_ID,
  ROUTE_QUERY_TIMESHEETS,
  ROUTE_TIME_CONFIRMATION_REPORT,
  ROUTE_TIME_CONFIRMATION_RESULTS,
  THE_HUB,
  UNPAID,
} from "../../SharedModule/utils/constants";
import {
  BiLeftArrowAlt,
  BiLockAlt,
  BiInfoCircle,
  BiEditAlt,
  BiTrash,
  BiCalendar,
} from "react-icons/bi";
import {
  FiltersTouched,
  GetFilters,
  IsShowAdditionalFilters,
  keepFilters,
  resetFilters,
  selectIsInfoQueryLoaded,
  setAdditionalFilters,
} from "../redux/reducers/timesQuery.reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  dateFormatEntryEdit,
  nameDayDateFormat,
  quantityFormat,
} from "../../SharedModule/utils/formatters";
import {
  camelCaseToWords,
  checkDataIsLoaded,
  firstLetterToLower,
  iconDeleteEnabled,
  iconEditEnabled,
  navigateTo,
  transformValue,
} from "../utils/timesUtils";
import { useEffect, useRef, useState } from "react";
import { SummaryTotals } from "../components/SummaryTotals";
import { useQueryResults } from "../hooks/useQueryResults";
import {
  EntryListView,
  SummaryByTask,
  SummaryByTaskType,
  SummaryByTaskWithName,
  SummaryByUser,
  SummaryByUserWithName,
  TimeEntryApprovalTime,
} from "../types";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { ModalPortal } from "../../SharedModule/components/ModalPortal";
import { ModalMoreInfoTimeEntry } from "../components/ModalMoreInfoTimeEntry";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { TimesheetsService } from "../services/timesheets";
import { ModalTimeEntry } from "../components/ModalTimeEntry";
import { ModalDeleteTimeEntry } from "../components/ModalDeleteTimeEntry";
import { setLoading } from "../redux/reducers/queryResult.reducer";
import { DayTotals } from "../components/DayTotals";
import { ActionsButton } from "../components/ActionsButton";
import { ModalActionNonBillable } from "../components/ModalActionNonBillable";
import { ModalActionChangeDate } from "../components/ModalActionChangeDate";
import { ModalActionChangeProject } from "../components/ModalActionChangeProject";
import { NoContent } from "../../SharedModule/components/NoContent";
import image from "./../../styles/legacy/404.png";
import Footer from "../../SharedModule/components/Footer";
import { useTopNavigationInfo } from "../../SharedModule/hooks/useTopNavigationInfo";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import eventHandler from "../../SharedModule/utils/eventHandler";
import {
  getLastWorkableDay,
  getPreviousWorkableDay,
} from "../../SharedModule/utils/dateUtils";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";

export const QueryResultsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { trackPageView } = useMatomo();

  const today = new Date();

  // Imported to use, when edit or delete a time entry from actual user, should update hours in header
  const { loadInfo } = useTopNavigationInfo();

  const [timeEntriesList, setTimeEntriesList] = useState([]);

  const isShowAdditionalFilters = useSelector(IsShowAdditionalFilters);

  const [entryActions, setEntryActions] = useState(undefined);
  const [activeEntry, setActiveEntry] = useState<EntryListView>(Object);

  const [showDetails, setShowDetails] = useState(true);

  const [entriesSelected, setEntriesSelected] = useState<
    TimeEntryApprovalTime[]
  >([]);

  const filtersTouched = useSelector(FiltersTouched);
  const filters = useSelector(GetFilters);

  const isInfoQueryLoaded = useSelector(selectIsInfoQueryLoaded);
  // times module admin
  const { isLoadingUserInfo } = useSelector(actualUIState);

  // ASUITE11-2711: is from time confirmation page, no need to load query filters so: isInfoQueryLoaded should be true
  let fromTimeConfirmation =
    localStorage.getItem("queryFromTimeConfirmation") === "true";

  let queryFromQueryPage =
    localStorage.getItem("queryFromQueryPage") === "true";

  if (fromTimeConfirmation && !filters.user) {
    navigate(ROUTE_TIME_CONFIRMATION_REPORT);
  }
  if (queryFromQueryPage && !isInfoQueryLoaded) {
    navigate(ROUTE_QUERY_TIMESHEETS);
  }

  const {
    summary,
    summaryByUser,
    summaryByTask,
    summaryByTaskType,
    queryResultList,
    isLoading,
    selectProjectOrWorkOrder,
    holidays,
    isTimeAdmin,
    isSuperTimeAdmin,
    isSupervisorOrPO,
    isTimeViewer,
    activeUserId,
    MetaTimesInfo,
    loadQueryResultsPageInfo,
  } = useQueryResults(
    filters,
    fromTimeConfirmation ? true : isInfoQueryLoaded,
    isLoadingUserInfo
  );

  let previousWorkableDay = getPreviousWorkableDay(1, holidays);
  let lastWorkableDay: Date = getLastWorkableDay();

  let showNoResultsOnNoSummaryData =
    (!summaryByUser || summaryByUser.length === 0) &&
    (!summaryByTask || summaryByTask.length === 0) &&
    (!summaryByTaskType || summaryByTaskType.length === 0);

  const getUserName = (userId: string) => {
    // to avoid issue on refresh from browser on query result page
    if (MetaTimesInfo.timeUsers) {
      let response: any = MetaTimesInfo.timeUsers?.filter(
        (user) => user.value === userId
      );
      return response[0].label;
    } else {
      navigate(ROUTE_QUERY_TIMESHEETS);
    }
  };

  let summaryByUserWithUserName: any = [];
  if (summaryByUser) {
    summaryByUser.forEach((element) => {
      summaryByUserWithUserName.push({
        ...element,
        userName: getUserName(element.userId),
      });
    });
  }

  let summaryByTaskWithUserName: any = [];
  let copyInfo: any = [];
  if (summaryByTask) {
    summaryByTask.forEach((element) => {
      element.info.forEach((user) => {
        copyInfo.push({ ...user, userName: getUserName(user.userId) });
      });
      copyInfo.sort((a: any, b: any) =>
        a.userName.toLowerCase() < b.userName.toLowerCase() ? -1 : 1
      );
      summaryByTaskWithUserName.push({
        ...element,
        info: copyInfo,
      });
      copyInfo = [];
    });
  }

  // When user is not admin, should not see PROJ_UNPAID_TIME_OFF_ID - ASUITE11-2464
  let selectProjectOrWorkOrderAfterFilter = selectProjectOrWorkOrder;
  if (!isTimeAdmin && selectProjectOrWorkOrder[1].options.length > 0) {
    selectProjectOrWorkOrderAfterFilter[1].options =
      selectProjectOrWorkOrder[1].options.filter(
        (project) => project.entityId !== PROJ_UNPAID_TIME_OFF_ID
      );
  }

  useEffect(() => {
    document.title = "The Hub - Query Results";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    // should go in every times page
    if (!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin, isTimeViewer)) {
      eventHandler.dispatch("loadTimesStaticInfo", {
        isTimeAdmin: isTimeAdmin,
        isTimeViewer: isTimeViewer,
      });
    }

    return () => {
      localStorage.removeItem("queryFromTimeConfirmation");
      localStorage.removeItem("queryFromQueryPage");
      // reset filters if user got to another page
      if (window.location.pathname !== ROUTE_QUERY_TIMESHEETS) {
        dispatch(keepFilters(false));
        dispatch(setAdditionalFilters(false));
      }
    };
  }, [trackPageView, isTimeAdmin, isTimeViewer]);

  const isSelected = (entry: any) => {
    let response = entriesSelected.indexOf(entry);
    return response > -1;
  };

  const updatedSelected = (entry: any) => {
    let response = entriesSelected.indexOf(entry);
    if (response < 0) {
      const dataUpdated = [entry, ...entriesSelected];
      setEntriesSelected(dataUpdated);
    } else {
      setEntriesSelected(
        entriesSelected.filter((item) => item.id !== entry.id)
      );
    }
  };

  const updateSelectedByDay = (date: string, value: boolean) => {
    let response = queryResultList[date].filter(
      (entry) => entry.source === THE_HUB
    );
    if (value) {
      const dataUpdated = [...response, ...entriesSelected];
      setEntriesSelected(dataUpdated);
    } else {
      setEntriesSelected(
        entriesSelected.filter((item) => item.entryDate !== date)
      );
    }
  };

  let listFiltersTouched: any[] = [];
  let listFiltersUntouched: any[] = [];

  const [userListId, setUserListId] = useState<string[]>([]);

  for (const property in filtersTouched) {
    if (filtersTouched[property]) {
      listFiltersTouched.push({
        name: camelCaseToWords(property),
        value: transformValue(property, filters[property]),
      });
    } else {
      listFiltersUntouched.push({
        name: camelCaseToWords(property),
        value: transformValue(property, filters[property]),
      });
    }
  }

  const backToQuery = (needNavigate: boolean) => {
    dispatch(keepFilters(true));
    dispatch(setAdditionalFilters(isShowAdditionalFilters));
    if (needNavigate) {
      navigate(ROUTE_QUERY_TIMESHEETS);
    }
  };

  const newQuery = () => {
    dispatch(keepFilters(false));
    dispatch(resetFilters());
    dispatch(setAdditionalFilters(false));
    navigate(ROUTE_QUERY_TIMESHEETS);
  };

  // Modal More Info config
  const modalMoreInfoRef = useRef();
  const showMoreInfoModal = () => {
    (modalMoreInfoRef.current as any).show(true);
  };
  const closeMoreInfoModal = () => {
    (modalMoreInfoRef.current as any).show(false);
  };

  const showMoreInfo = async (entry: EntryListView) => {
    await TimesheetsService.getTimeEntryInfo(entry.timeEntryId)
      .then((response) => {
        if (response.data.data.length > 0) {
          setEntryActions(response.data.data);
          showMoreInfoModal();
        } else {
          dispatch(
            addToast({ mode: "error", message: "Time entry was not found" })
          );
        }
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error }));
      });
  };

  // Modal config
  const modalRef = useRef();
  const showModal = () => {
    (modalRef.current as any).show(true);
  };
  const closeModal = () => {
    (modalRef.current as any).show(false);
  };

  // Modal Delete config
  const modalDeleteRef = useRef();
  const showDeleteModal = () => {
    (modalDeleteRef.current as any).show(true);
  };
  const closeDeleteModal = () => {
    (modalDeleteRef.current as any).show(false);
  };

  // Modal Action billable
  const ModalActionNonBillableRef = useRef();
  const showActionNonBillaleModal = () => {
    (ModalActionNonBillableRef.current as any).show(true);
  };
  const closeActionNonBillaleModal = () => {
    (ModalActionNonBillableRef.current as any).show(false);
  };

  // Modal Action change date
  const modalActionChangeDateRef = useRef();
  const showActionChangeDateModal = () => {
    (modalActionChangeDateRef.current as any).show(true);
  };
  const closeActionChangeDateModal = () => {
    (modalActionChangeDateRef.current as any).show(false);
  };

  // Modal Action change project
  const modalActionChangeProjectRef = useRef();
  const showActionChangeProjectModal = () => {
    let partailUserListId: string[] = [];
    entriesSelected.forEach((entry) => {
      if (!partailUserListId.includes(entry.userId)) {
        partailUserListId.push(entry.userId);
      }
    });
    setUserListId(partailUserListId);
    (modalActionChangeProjectRef.current as any).show(true);
  };
  const closeActionChangeProjectModal = () => {
    (modalActionChangeProjectRef.current as any).show(false);
  };

  // refresh info page, also with condition if user should be update header
  const refreshPage = () => {
    // if user is not admin or is admin and is adding own hours
    if (!isTimeAdmin || activeUserId === activeEntry.userId) {
      loadInfo();
    }
    loadQueryResultsPageInfo();
  };

  const loadBodyToupdateTimeEntries = (action: number, value: any) => {
    // 1-billable 2-nonBillable 3-approved 4-unapprove 5=moveDate 6-moveProject
    let body = {
      timeEntriesIds:
        action === 3
          ? entriesSelected
              .filter((elem) => elem.status !== APPROVED_TEXT)
              .map((elem) => elem.timeEntryId)
          : entriesSelected.map((elem) => elem.timeEntryId),
      action: {
        id: action,
        nonBillableReasonId: action === 2 ? value : null,
        entryDate: action === 5 ? value : null,
        entityId: action === 6 ? value.entityId : null,
        categoryId: action === 6 ? value.categoryId : null,
      },
    };
    return body;
  };

  const updateEntriesSelected = async (action: number, value: any) => {
    // close modals
    if (action === 5) {
      closeActionChangeDateModal();
    } else if (action === 6) {
      closeActionChangeProjectModal();
    } else if (action === 2) {
      closeActionNonBillaleModal();
    }
    // set loading to true
    dispatch(setLoading(true));

    await TimesheetsService.updateTimeEntries(
      loadBodyToupdateTimeEntries(action, value)
    )
      .then((response) => {
        dispatch(addToast({ mode: "success", message: response.data.message }));
        loadQueryResultsPageInfo();

        // clean entries selected
        setEntriesSelected([]);
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error }));
      });
  };

  const editEntry = (entry: EntryListView) => {
    // all logic to edit enabled is on iconEditEnabled
    setActiveEntry(entry);
    // select time entries by same day and same user as actual entry
    setTimeEntriesList(
      queryResultList[entry.entryDate].filter(
        (elem) => elem.userId === entry.userId
      )
    );
    showModal();
  };

  const deleteEntry = (entry: EntryListView) => {
    setActiveEntry(entry);
    showDeleteModal();
  };

  const deleteEntryModal = async () => {
    closeDeleteModal();
    dispatch(setLoading(true));
    await TimesheetsService.deleteTimesEntry(activeEntry.timeEntryId)
      .then((response) => {
        dispatch(addToast({ mode: "success", message: response.data.message }));
        refreshPage();
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error }));
      });
  };

  return (
    <>
      <ModalPortal ref={modalMoreInfoRef}>
        <ModalMoreInfoTimeEntry
          btnCancel={closeMoreInfoModal}
          type="moreInfoModal"
          actions={entryActions}
        />
      </ModalPortal>
      <ModalPortal ref={modalRef}>
        <ModalTimeEntry
          title="Modal Entry"
          projectList={selectProjectOrWorkOrderAfterFilter}
          btnCancel={closeModal}
          reloadPage={refreshPage}
          type="formModal"
          editMode={true}
          entry={activeEntry}
          userIdParam={activeEntry.userId}
          holidays={holidays}
          deleteEntry={deleteEntry}
          timeEntriesList={timeEntriesList}
          isQueryPage={true}
        />
      </ModalPortal>
      <ModalPortal ref={modalDeleteRef}>
        <ModalDeleteTimeEntry
          btnCancel={closeDeleteModal}
          btnAction={deleteEntryModal}
        />
      </ModalPortal>
      <ModalPortal ref={ModalActionNonBillableRef}>
        <ModalActionNonBillable
          btnCancel={closeActionNonBillaleModal}
          btnAction={updateEntriesSelected}
          nonBillableReasons={MetaTimesInfo.nonBillableReasons}
        />
      </ModalPortal>
      <ModalPortal ref={modalActionChangeDateRef}>
        <ModalActionChangeDate
          btnCancel={closeActionChangeDateModal}
          btnAction={updateEntriesSelected}
        />
      </ModalPortal>
      <ModalPortal ref={modalActionChangeProjectRef}>
        <ModalActionChangeProject
          btnCancel={closeActionChangeProjectModal}
          btnAction={updateEntriesSelected}
          userListId={userListId}
        />
      </ModalPortal>
      <div className="content">
        <div className="mt-2 button-header">
          {!fromTimeConfirmation && (
            <Link
              className="back-button-container text-decoration-none"
              to={ROUTE_QUERY_TIMESHEETS}
              onClick={() => backToQuery(false)}
            >
              <BiLeftArrowAlt className="back-button-arrow" />
              <span className="back-button-text">Back to Query</span>
            </Link>
          )}
          {fromTimeConfirmation && (
            <Link
              className="back-button-container text-decoration-none"
              to={ROUTE_TIME_CONFIRMATION_RESULTS}
            >
              <BiLeftArrowAlt className="back-button-arrow" />
              <span className="back-button-text">
                Back to Time Confirmation Report
              </span>
            </Link>
          )}
          <button className="btn button-secondary" onClick={() => newQuery()}>
            New Query
          </button>
        </div>
        <div className="d-flex flex-wrap mb-1">
          <h2 className="flex-fill">Results</h2>
        </div>
        <div className="flex-container flex-wrap">
          <div className="col-lg-4 col-12 d-inline-flex mb-3">
            <div className="col-4">From:</div>
            <CheckSkeleton isLoading={isLoading} classes="col-7">
              <div className="col-7 fw-bold">{`${dateFormatEntryEdit(
                filters.startDate
              )} - ${dateFormatEntryEdit(filters.endDate)}`}</div>
            </CheckSkeleton>
          </div>
          {listFiltersTouched &&
            listFiltersTouched.length > 0 &&
            listFiltersTouched.map((filter: any) => {
              return (
                <div
                  className="col-lg-4 col-12 d-inline-flex mb-3"
                  key={filter.name}
                >
                  <div className="col-4">{filter.name}:</div>
                  <CheckSkeleton isLoading={isLoading} classes="col-7">
                    <>
                      {filter.name === "Projects" &&
                        (filter.value === ALL_PROJ_WO ||
                          filter.value === ALL_WO_PROJ) && (
                          <div className="col-7 fw-bold">
                            {ALL_PROJECTS_AND_WORK_ORDERS}
                          </div>
                        )}
                      {(filter.name !== "Projects" ||
                        (filter.value !== ALL_PROJ_WO &&
                          filter.value !== ALL_WO_PROJ)) && (
                        <div className="col-7 fw-bold">{filter.value}</div>
                      )}
                    </>
                  </CheckSkeleton>
                </div>
              );
            })}
          {!showDetails &&
            listFiltersUntouched &&
            listFiltersUntouched.length > 0 &&
            listFiltersUntouched.map((filter: any) => {
              return (
                <div
                  className="col-lg-4 col-12 d-inline-flex mb-3"
                  key={filter.name}
                >
                  <div className="col-4">{filter.name}:</div>
                  <CheckSkeleton isLoading={isLoading} classes="col-7">
                    <div className="col-7 fw-bold">{filter.value}</div>
                  </CheckSkeleton>
                </div>
              );
            })}
        </div>
        <div className="d-flex flex-wrap mb-2">
          <div
            className="link-text text-decoration-underline"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? "Show All Filters" : "Hide Details"}
          </div>
        </div>
        <SummaryTotals
          showTitle={false}
          totals={summary.totalHours}
          billable={summary.billableHours}
          financed={summary.financedHours}
          nonBillable={summary.nonBillableHours}
          internal={summary.internalHours}
          timeOff={summary.timeOffHours}
          isLoading={isLoading}
          avoidCheckMissingHours={true}
        />
        <hr className="header-separator mt-4" />

        {!filters.summary.hideEntries &&
          isTimeAdmin &&
          queryResultList &&
          Object.keys(queryResultList).length > 0 && (
            <div className="d-flex mt-3 justify-content-end">
              <div className="view-buttons">
                <ActionsButton
                  disabled={entriesSelected.length === 0}
                  entriesSelected={entriesSelected}
                  showActionNonBillaleModal={showActionNonBillaleModal}
                  showActionChangeDateModal={showActionChangeDateModal}
                  showActionChangeProjectModal={showActionChangeProjectModal}
                  updateEntriesSelected={updateEntriesSelected}
                  activeUserId={activeUserId}
                />
              </div>
            </div>
          )}

        {!isLoading &&
          ((!filters.summary.hideEntries &&
            queryResultList &&
            Object.keys(queryResultList).length === 0) ||
            ((!queryResultList ||
              (queryResultList && Object.keys(queryResultList).length === 0)) &&
              showNoResultsOnNoSummaryData)) && (
            <NoContent
              title="No Results Found"
              text="Try adjusting your query to find what you’re looking for."
              action={() => backToQuery(true)}
              conditionButton={true}
              image={image}
              style={{ margin: "3% auto" }}
              textButton="Edit Query"
            />
          )}

        {!isLoading &&
          !filters.summary.hideEntries &&
          queryResultList &&
          Object.keys(queryResultList).length > 0 &&
          Object.keys(queryResultList)
            .sort()
            .map((keyName) => {
              let actualSummary = {
                total: 0,
                billable: 0,
                nonBillable: 0,
                internal: 0,
                timeOff: 0,
              };
              queryResultList[keyName].forEach((element) => {
                actualSummary[firstLetterToLower(element.timeEntryType)] +=
                  element.hours;
                actualSummary["total"] += element.hours;
              });
              return (
                <>
                  <div
                    className="d-flex mt-4 justify-content-between sticky-date sticky"
                    key={keyName + "-summary"}
                  >
                    <div className="left-side d-flex align-items-center">
                      <BiCalendar style={{ width: "20px", height: "20px" }} />
                      <div className="table-date ms-2">
                        {nameDayDateFormat(
                          queryResultList[keyName][0].entryDate
                        )}
                      </div>
                    </div>
                    <DayTotals daySummary={actualSummary} />
                  </div>

                  <table
                    className="table white-table-header-background my-3"
                    style={{ width: "100%" }}
                    key={"table-" + keyName}
                  >
                    <thead className="align-middle sticky-header-with-date sticky">
                      <tr>
                        {isTimeAdmin && (
                          <th className="text-center border-dark border-top fw-500 text-center py-1 column-5 small">
                            Select
                            <div className="d-flex justify-content-center">
                              <div
                                className="link-text mx-2"
                                onClick={() =>
                                  updateSelectedByDay(
                                    queryResultList[keyName][0].entryDate,
                                    true
                                  )
                                }
                              >
                                All
                              </div>
                              /
                              <div
                                className="link-text mx-2"
                                onClick={() =>
                                  updateSelectedByDay(
                                    queryResultList[keyName][0].entryDate,
                                    false
                                  )
                                }
                              >
                                None
                              </div>
                            </div>
                          </th>
                        )}
                        <th
                          className={`border-dark border-top fw-500 py-0 ps-4 column-${
                            isTimeAdmin ? "20" : "25"
                          }`}
                        >
                          Client / Project / Task
                        </th>
                        <th className="border-dark border-top fw-500 py-0 ps-4 column-15">
                          Hours / Task Type
                        </th>
                        <th className="border-dark border-top fw-500 py-0 ps-4 column-30">
                          Description
                        </th>
                        <th className="border-dark border-top fw-500 py-0 ps-4 column-15">
                          Billable/Price
                        </th>
                        <th className="border-dark border-top fw-500 py-0 column-5">
                          Status
                        </th>
                        <th className="border-dark border-top fw-500 py-0 ps-3 column-15">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {queryResultList[keyName].map((entry: any) => {
                        return (
                          <tr
                            key={"row-" + entry.timeEntryId}
                            style={{ verticalAlign: "middle" }}
                          >
                            {isTimeAdmin && (
                              <td className="table-td text-center py-0">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={isSelected(entry)}
                                  disabled={
                                    entry.source !== THE_HUB || !entry.canEdit
                                  }
                                  onChange={() => updatedSelected(entry)}
                                />
                              </td>
                            )}
                            <td className="table-td ps-4 py-3 fw-500">
                              {entry.categoryId !== 3 && (
                                <>
                                  <div className="td-small-text">
                                    {entry.clientName}
                                  </div>
                                  <a
                                    className="td-task-link"
                                    href={navigateTo(entry)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Project{" "}
                                    {entry.projectId
                                      ? entry.projectId
                                      : entry.entityId}{" "}
                                    - {entry.entityDescription}
                                  </a>
                                </>
                              )}
                              {entry.categoryId === 3 && (
                                <>
                                  <div className="td-small-text">
                                    {entry.clientName}
                                  </div>
                                  <a
                                    className="td-task-link"
                                    href={navigateTo(entry)}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Work Order {entry.entityId} -{" "}
                                    {entry.entityDescription}
                                  </a>
                                </>
                              )}
                              <div className="td-small-text mt-1">
                                {getUserName(entry.userId)}
                              </div>
                            </td>
                            <td className="table-td ps-4 py-3 fw-500">
                              <div
                                className={`td-bold ${
                                  entry.hours % 0.25 ? "text-error" : ""
                                }`}
                              >
                                {quantityFormat(entry.hours)}
                              </div>
                              <div className="td-text">{entry.taskType}</div>
                            </td>
                            <td className="table-td ps-4 py-3 fw-500">
                              <div className="td-text text-wrap">
                                {entry.description}
                              </div>
                            </td>
                            <td className="table-td ps-4 py-3 fw-500">
                              <div className="td-bold">
                                {entry.timeEntryType === BILLABLE
                                  ? "Yes"
                                  : entry.timeEntryType === UNPAID
                                  ? "Unpaid"
                                  : "No"}
                                {entry.timeEntryTypeId === 3 ? "-Financed" : ""}
                              </div>
                              {entry.timeEntryType === BILLABLE && (
                                <div className="td-small-text">
                                  {entry.userPriceLevel}
                                </div>
                              )}
                              {nonBillableIds.includes(
                                entry.timeEntryTypeId.toString().toLowerCase()
                              ) && (
                                <div className="td-small-text">
                                  {entry.nonBillableReason}
                                </div>
                              )}
                              {isTimeAdmin && (
                                <div className="td-text">
                                  ${quantityFormat(entry.price)}
                                </div>
                              )}
                            </td>
                            <td className="table-td py-4 fw-500">
                              <div
                                className={`td-status ${entry.status} ${
                                  entry.status === LOCKED ? "d-flex" : ""
                                }`}
                              >
                                {entry.status}
                                {entry.status === LOCKED && (
                                  <BiLockAlt
                                    className="ms-1"
                                    style={{ width: "16px", height: "16px" }}
                                  />
                                )}
                              </div>
                            </td>
                            <td className="table-td action-icons-td py-4">
                              <div className="d-flex flex-column">
                                {(entry.timeEntrySourceId === 3 ||
                                  (entry.timeEntrySourceId === 2 &&
                                    !entry.sourceUrl)) && (
                                  <div className="entry-text-imported link-black">
                                    From {entry.source}
                                  </div>
                                )}
                                {entry.timeEntrySourceId === 2 &&
                                  entry.sourceUrl && (
                                    <Link
                                      to={entry.sourceUrl}
                                      className="entry-text-imported text-decoration-none other-links-hover"
                                    >
                                      From {entry.source}
                                    </Link>
                                  )}
                                <div>
                                  <Tooltip
                                    title={<b>More Info</b>}
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <span>
                                      <BiInfoCircle
                                        className={`icon-action ${
                                          !isLoading ? "" : "disabled"
                                        }`}
                                        onClick={() => showMoreInfo(entry)}
                                      />
                                    </span>
                                  </Tooltip>
                                  <Tooltip
                                    title={<b>Edit</b>}
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <span>
                                      <BiEditAlt
                                        className={`icon-action ${
                                          !isLoading &&
                                          iconEditEnabled(
                                            entry,
                                            activeUserId,
                                            entry.userId,
                                            isSuperTimeAdmin,
                                            isSupervisorOrPO,
                                            isTimeViewer,
                                            previousWorkableDay,
                                            today,
                                            lastWorkableDay
                                          )
                                            ? ""
                                            : "disabled"
                                        }`}
                                        onClick={() =>
                                          !isLoading &&
                                          iconEditEnabled(
                                            entry,
                                            activeUserId,
                                            entry.userId,
                                            isSuperTimeAdmin,
                                            isSupervisorOrPO,
                                            isTimeViewer,
                                            previousWorkableDay,
                                            today,
                                            lastWorkableDay
                                          )
                                            ? editEntry(entry)
                                            : undefined
                                        }
                                      />
                                    </span>
                                  </Tooltip>
                                  <Tooltip
                                    title={<b>Delete</b>}
                                    placement="top"
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <span>
                                      <BiTrash
                                        className={`icon-action ${
                                          !isLoading &&
                                          iconDeleteEnabled(
                                            entry,
                                            activeUserId,
                                            entry.userId,
                                            isSuperTimeAdmin,
                                            isSupervisorOrPO,
                                            isTimeViewer,
                                            previousWorkableDay,
                                            today,
                                            lastWorkableDay
                                          )
                                            ? ""
                                            : "disabled"
                                        }`}
                                        onClick={() =>
                                          !isLoading &&
                                          iconDeleteEnabled(
                                            entry,
                                            activeUserId,
                                            entry.userId,
                                            isSuperTimeAdmin,
                                            isSupervisorOrPO,
                                            isTimeViewer,
                                            previousWorkableDay,
                                            today,
                                            lastWorkableDay
                                          )
                                            ? deleteEntry(entry)
                                            : undefined
                                        }
                                      />
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              );
            })}

        {!isLoading &&
          filters.summary.byUser &&
          summaryByUserWithUserName &&
          summaryByUserWithUserName.length > 0 && (
            <>
              <div className="query-summary-title my-3">Summary by User</div>
              <table
                className="table white-table-header-background my-3"
                style={{ width: "100%" }}
                key="table-by-user"
              >
                <thead
                  className="align-middle sticky-header-with-date sticky sticky-row"
                  style={{ zIndex: "60" }}
                >
                  <tr>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-4 column-20">
                      User
                    </th>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-4 column-30">
                      Client
                    </th>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-4 column-30">
                      Project/WO
                    </th>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-3 column-10 text-center">
                      Hours
                    </th>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-3 column-10 text-center">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {summaryByUserWithUserName
                    .sort((a: any, b: any) =>
                      a.userName.toLowerCase() < b.userName.toLowerCase()
                        ? -1
                        : 1
                    )
                    .map((elem: SummaryByUserWithName) => {
                      return (
                        <>
                          <tr
                            className={
                              elem.count === 1 ? "tr-double-border" : ""
                            }
                            style={{ verticalAlign: "middle" }}
                          >
                            <td
                              className="table-td ps-4 py-3 fw-500 td-summary-query no-left"
                              rowSpan={elem.count > 0 ? elem.count : 1}
                            >
                              <div className="td-text text-wrap fw-bold">
                                {elem.userName}
                              </div>
                            </td>
                            <td
                              className="table-td ps-4 py-3 fw-500 td-summary-query no-right"
                              rowSpan={
                                elem.info.length > 0
                                  ? elem.info[0].projects.length
                                  : 1
                              }
                            >
                              <div className="td-text text-wrap">
                                {elem.info.length > 0
                                  ? elem.info[0].client
                                  : ""}
                              </div>
                            </td>
                            <td
                              className={`table-td ps-4 py-3 fw-500 td-summary-query no-sides ${
                                elem.info.length > 0 &&
                                elem.info[0].projects.length > 1
                                  ? "no-bottom"
                                  : ""
                              }`}
                            >
                              <div className="td-text text-wrap">
                                {elem.info.length > 0 &&
                                elem.info[0].projects.length > 0
                                  ? elem.info[0].projects[0].project
                                  : ""}
                              </div>
                            </td>
                            <td
                              className={`table-td ps-4 py-3 fw-500 td-summary-query no-left ${
                                elem.info.length > 0 &&
                                elem.info[0].projects.length > 1
                                  ? "no-bottom"
                                  : ""
                              }`}
                            >
                              <div className="td-text text-wrap text-center">
                                {elem.info.length > 0 &&
                                elem.info[0].projects.length > 0
                                  ? quantityFormat(
                                      elem.info[0].projects[0].hours
                                    )
                                  : ""}
                              </div>
                            </td>
                            <td
                              className="table-td ps-4 py-3 fw-500 td-summary-query no-right"
                              rowSpan={elem.count > 0 ? elem.count : 1}
                            >
                              <div className="td-text text-wrap text-center fw-bold">
                                {quantityFormat(elem.totalHours)}
                              </div>
                            </td>
                          </tr>
                          {elem.count > 1 &&
                            elem.info.length > 0 &&
                            elem.info[0].projects.length > 1 &&
                            elem.info[0].projects.map((proj: any, index) => {
                              return index !== 0 ? (
                                <tr style={{ verticalAlign: "middle" }}>
                                  <td
                                    className={`table-td ps-4 py-3 fw-500 td-summary-query no-sides no-top ${
                                      index !== elem.info[0].projects.length - 1
                                        ? "no-bottom"
                                        : ""
                                    }`}
                                  >
                                    <div className="td-text text-wrap">
                                      {proj.project}
                                    </div>
                                  </td>
                                  <td
                                    className={`table-td ps-4 py-3 fw-500 td-summary-query no-left no-top ${
                                      index !== elem.info[0].projects.length - 1
                                        ? "no-bottom"
                                        : ""
                                    }`}
                                  >
                                    <div className="td-text text-wrap text-center">
                                      {quantityFormat(proj.hours)}
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              );
                            })}
                          {elem.count > 1 &&
                            elem.info.length > 1 &&
                            elem.info.map((client: any, index) => {
                              return index !== 0 ? (
                                <>
                                  <tr
                                    className={
                                      elem.info.length === index + 1 &&
                                      elem.info[index].projects.length === 1
                                        ? "tr-double-border"
                                        : ""
                                    }
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    <td
                                      className="table-td ps-4 py-3 fw-500 td-summary-query no-right no-top"
                                      rowSpan={
                                        client.projects.length > 0
                                          ? client.projects.length
                                          : 1
                                      }
                                    >
                                      <div className="td-text text-wrap">
                                        {client.client}
                                      </div>
                                    </td>
                                    <td
                                      className={`table-td ps-4 py-3 fw-500 td-summary-query no-sides no-top ${
                                        client.projects.length > 1
                                          ? "no-bottom"
                                          : ""
                                      }`}
                                    >
                                      <div className="td-text text-wrap">
                                        {client.projects.length > 0
                                          ? client.projects[0].project
                                          : ""}
                                      </div>
                                    </td>
                                    <td
                                      className={`table-td ps-4 py-3 fw-500 td-summary-query no-left no-top ${
                                        client.projects.length > 1
                                          ? "no-bottom"
                                          : ""
                                      }`}
                                    >
                                      <div className="td-text text-wrap text-center">
                                        {client.projects.length > 0
                                          ? quantityFormat(
                                              client.projects[0].hours
                                            )
                                          : ""}
                                      </div>
                                    </td>
                                  </tr>
                                  {client.projects.length > 1 &&
                                    client.projects.map(
                                      (proj: any, indexClient) => {
                                        return indexClient !== 0 ? (
                                          <tr
                                            className={
                                              client.projects.length ===
                                                indexClient + 1 &&
                                              elem.info.length === index + 1
                                                ? "tr-double-border"
                                                : ""
                                            }
                                            style={{ verticalAlign: "middle" }}
                                          >
                                            <td
                                              className={`table-td ps-4 py-3 fw-500 td-summary-query no-sides no-top ${
                                                indexClient !==
                                                client.projects.length - 1
                                                  ? "no-bottom"
                                                  : ""
                                              }`}
                                            >
                                              <div className="td-text text-wrap">
                                                {proj.project}
                                              </div>
                                            </td>
                                            <td
                                              className={`table-td ps-4 py-3 fw-500 td-summary-query no-left no-top ${
                                                indexClient !==
                                                client.projects.length - 1
                                                  ? "no-bottom"
                                                  : ""
                                              }`}
                                            >
                                              <div className="td-text text-wrap text-center">
                                                {quantityFormat(proj.hours)}
                                              </div>
                                            </td>
                                          </tr>
                                        ) : (
                                          <></>
                                        );
                                      }
                                    )}
                                </>
                              ) : (
                                <></>
                              );
                            })}
                        </>
                      );
                    })}
                </tbody>
              </table>
            </>
          )}

        {!isLoading &&
          filters.summary.byTask &&
          summaryByTaskWithUserName &&
          summaryByTaskWithUserName.length > 0 && (
            <>
              <div className="query-summary-title my-3">Summary by Task</div>
              <table
                className="table white-table-header-background my-3"
                style={{ width: "100%" }}
                key="table-by-task"
              >
                <thead
                  className="align-middle sticky-header-with-date sticky sticky-row"
                  style={{ zIndex: "60" }}
                >
                  <tr>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-4 column-25">
                      Task
                    </th>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-4 column-20">
                      Client
                    </th>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-4 column-20">
                      Project/WO
                    </th>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-4 column-15">
                      User
                    </th>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-3 column-10 text-center">
                      Hours
                    </th>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-3 column-10 text-center">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {summaryByTaskWithUserName.map(
                    (elem: SummaryByTaskWithName) => {
                      return (
                        <>
                          <tr
                            className={
                              elem.count === 1 ? "tr-double-border" : ""
                            }
                            style={{ verticalAlign: "middle" }}
                          >
                            <td
                              className="table-td ps-4 py-3 fw-500 td-summary-query no-sides"
                              rowSpan={elem.count > 0 ? elem.count : 1}
                            >
                              <div className="td-text text-wrap fw-bold">
                                {elem.task}
                              </div>
                            </td>
                            <td
                              className="table-td ps-4 py-3 fw-500 td-summary-query no-sides"
                              rowSpan={elem.count > 0 ? elem.count : 1}
                            >
                              <div className="td-text text-wrap">
                                {elem.client}
                              </div>
                            </td>
                            <td
                              className="table-td ps-4 py-3 fw-500 td-summary-query no-left"
                              rowSpan={elem.count > 0 ? elem.count : 1}
                            >
                              <div className="td-text text-wrap">
                                {elem.project}
                              </div>
                            </td>
                            <td className="table-td ps-4 py-3 fw-500 td-summary-query no-right">
                              <div className="td-text text-wrap">
                                {elem.info.length > 0
                                  ? elem.info[0].userName
                                  : ""}
                              </div>
                            </td>
                            <td className="table-td ps-4 py-3 fw-500 td-summary-query no-left">
                              <div className="td-text text-wrap text-center">
                                {elem.info.length > 0
                                  ? quantityFormat(elem.info[0].hours)
                                  : ""}
                              </div>
                            </td>
                            <td
                              className="table-td ps-4 py-3 fw-500 td-summary-query no-right"
                              rowSpan={elem.count > 0 ? elem.count : 1}
                            >
                              <div className="td-text text-wrap text-center fw-bold">
                                {quantityFormat(elem.totalHours)}
                              </div>
                            </td>
                          </tr>
                          {elem.count > 1 &&
                            elem.info.length > 1 &&
                            elem.info.map((user: any, index) => {
                              return index !== 0 ? (
                                <tr
                                  className={
                                    elem.info.length === index + 1
                                      ? "tr-double-border"
                                      : ""
                                  }
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <td className="table-td ps-4 py-3 fw-500 td-summary-query no-right no-top">
                                    <div className="td-text text-wrap">
                                      {user.userName}
                                    </div>
                                  </td>
                                  <td className="table-td ps-4 py-3 fw-500 td-summary-query no-left no-top">
                                    <div className="td-text text-wrap text-center">
                                      {quantityFormat(user.hours)}
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              );
                            })}
                        </>
                      );
                    }
                  )}
                </tbody>
              </table>
            </>
          )}

        {!isLoading &&
          filters.summary.byTaskType &&
          summaryByTaskType &&
          summaryByTaskType.length > 0 && (
            <>
              <div className="query-summary-title my-3">
                Summary by Task Type
              </div>
              <table
                className="table white-table-header-background my-3"
                style={{ width: "100%" }}
                key="table-by-task-type"
              >
                <thead
                  className="align-middle sticky-header-with-date sticky sticky-row"
                  style={{ zIndex: "60" }}
                >
                  <tr>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-4 column-20">
                      Task Type
                    </th>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-4 column-30">
                      Client
                    </th>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-4 column-30">
                      Project/WO
                    </th>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-3 column-10 text-center">
                      Hours
                    </th>
                    <th className="td-summary-query no-sides fw-500 py-0 ps-3 column-10 text-center">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {summaryByTaskType.map((elem: SummaryByTaskType) => {
                    return (
                      <>
                        <tr
                          className={elem.count === 1 ? "tr-double-border" : ""}
                          style={{ verticalAlign: "middle" }}
                        >
                          <td
                            className="table-td ps-4 py-3 fw-500 td-summary-query no-left"
                            rowSpan={elem.count > 0 ? elem.count : 1}
                          >
                            <div className="td-text text-wrap fw-bold">
                              {elem.taskType}
                            </div>
                          </td>
                          <td
                            className="table-td ps-4 py-3 fw-500 td-summary-query no-right"
                            rowSpan={
                              elem.info.length > 0
                                ? elem.info[0].projects.length
                                : 1
                            }
                          >
                            <div className="td-text text-wrap">
                              {elem.info.length > 0 ? elem.info[0].client : ""}
                            </div>
                          </td>
                          <td
                            className={`table-td ps-4 py-3 fw-500 td-summary-query no-sides ${
                              elem.info.length > 0 &&
                              elem.info[0].projects.length > 1
                                ? "no-bottom"
                                : ""
                            }`}
                          >
                            <div className="td-text text-wrap">
                              {elem.info.length > 0 &&
                              elem.info[0].projects.length > 0
                                ? elem.info[0].projects[0].project
                                : ""}
                            </div>
                          </td>
                          <td
                            className={`table-td ps-4 py-3 fw-500 td-summary-query no-left ${
                              elem.info.length > 0 &&
                              elem.info[0].projects.length > 1
                                ? "no-bottom"
                                : ""
                            }`}
                          >
                            <div className="td-text text-wrap text-center">
                              {elem.info.length > 0 &&
                              elem.info[0].projects.length > 0
                                ? quantityFormat(elem.info[0].projects[0].hours)
                                : ""}
                            </div>
                          </td>
                          <td
                            className="table-td ps-4 py-3 fw-500 td-summary-query no-right"
                            rowSpan={elem.count > 0 ? elem.count : 1}
                          >
                            <div className="td-text text-wrap text-center fw-bold">
                              {quantityFormat(elem.totalHours)}
                            </div>
                          </td>
                        </tr>
                        {elem.count > 1 &&
                          elem.info.length > 0 &&
                          elem.info[0].projects.length > 1 &&
                          elem.info[0].projects.map((proj: any, index) => {
                            return index !== 0 ? (
                              <tr style={{ verticalAlign: "middle" }}>
                                <td
                                  className={`table-td ps-4 py-3 fw-500 td-summary-query no-sides no-top ${
                                    index !== elem.info[0].projects.length - 1
                                      ? "no-bottom"
                                      : ""
                                  }`}
                                >
                                  <div className="td-text text-wrap">
                                    {proj.project}
                                  </div>
                                </td>
                                <td
                                  className={`table-td ps-4 py-3 fw-500 td-summary-query no-left no-top ${
                                    index !== elem.info[0].projects.length - 1
                                      ? "no-bottom"
                                      : ""
                                  }`}
                                >
                                  <div className="td-text text-wrap text-center">
                                    {quantityFormat(proj.hours)}
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <></>
                            );
                          })}
                        {elem.count > 1 &&
                          elem.info.length > 1 &&
                          elem.info.map((client: any, index) => {
                            return index !== 0 ? (
                              <>
                                <tr
                                  className={
                                    elem.info.length === index + 1 &&
                                    elem.info[index].projects.length === 1
                                      ? "tr-double-border"
                                      : ""
                                  }
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <td
                                    className="table-td ps-4 py-3 fw-500 td-summary-query no-right no-top"
                                    rowSpan={
                                      client.projects.length > 0
                                        ? client.projects.length
                                        : 1
                                    }
                                  >
                                    <div className="td-text text-wrap">
                                      {client.client}
                                    </div>
                                  </td>
                                  <td
                                    className={`table-td ps-4 py-3 fw-500 td-summary-query no-sides no-top ${
                                      client.projects.length > 1
                                        ? "no-bottom"
                                        : ""
                                    }`}
                                  >
                                    <div className="td-text text-wrap">
                                      {client.projects.length > 0
                                        ? client.projects[0].project
                                        : ""}
                                    </div>
                                  </td>
                                  <td
                                    className={`table-td ps-4 py-3 fw-500 td-summary-query no-left no-top ${
                                      client.projects.length > 1
                                        ? "no-bottom"
                                        : ""
                                    }`}
                                  >
                                    <div className="td-text text-wrap text-center">
                                      {client.projects.length > 0
                                        ? quantityFormat(
                                            client.projects[0].hours
                                          )
                                        : ""}
                                    </div>
                                  </td>
                                </tr>
                                {client.projects.length > 1 &&
                                  client.projects.map(
                                    (proj: any, indexClient) => {
                                      return indexClient !== 0 ? (
                                        <tr
                                          className={
                                            client.projects.length ===
                                              indexClient + 1 &&
                                            elem.info.length === indexClient + 1
                                              ? "tr-double-border"
                                              : ""
                                          }
                                        >
                                          <td
                                            className={`table-td ps-4 py-3 fw-500 td-summary-query no-sides no-top ${
                                              indexClient !==
                                              client.projects.length - 1
                                                ? "no-bottom"
                                                : ""
                                            }`}
                                          >
                                            <div className="td-text text-wrap">
                                              {proj.project}
                                            </div>
                                          </td>
                                          <td
                                            className={`table-td ps-4 py-3 fw-500 td-summary-query no-left no-top ${
                                              indexClient !==
                                              client.projects.length - 1
                                                ? "no-bottom"
                                                : ""
                                            }`}
                                          >
                                            <div className="td-text text-wrap text-center">
                                              {quantityFormat(proj.hours)}
                                            </div>
                                          </td>
                                        </tr>
                                      ) : (
                                        <></>
                                      );
                                    }
                                  )}
                              </>
                            ) : (
                              <></>
                            );
                          })}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}

        <Footer />
      </div>
    </>
  );
};
