import { useSelector } from "react-redux";
import {
  GetKeepFiltersFlag,
  IsShowAdditionalFilters,
  selectClientsQuery,
  selectCostCentersQuery,
  selectCountriesQuery,
  SelectFilters,
  SelectInitialStateFilters,
  selectIsInfoQueryLoaded,
  selectProjectManagersQuery,
  selectProjectsQuery,
  selectUsersQuery,
} from "../redux/reducers/timesQuery.reducer";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { hasTimeAdminAccess, hasTimeViewerAccess } from "../utils/timesUtils";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";

export const useQueryPage = () => {
  const isInfoQueryLoaded = useSelector(selectIsInfoQueryLoaded);
  const costCentersQuery = useSelector(selectCostCentersQuery);
  const clientsQuery = useSelector(selectClientsQuery);
  const projectsQuery = useSelector(selectProjectsQuery);
  const projectManagersQuery = useSelector(selectProjectManagersQuery);
  const usersQuery = useSelector(selectUsersQuery);
  const countriesQuery = useSelector(selectCountriesQuery);

  const MetaTimesInfo = useSelector(selectMetaTimesData);
  const filters = useSelector(SelectFilters);
  const isShowAdditionalFilters = useSelector(IsShowAdditionalFilters);
  const initialStatefilters = useSelector(SelectInitialStateFilters);
  const { userRolesTimesModule } = useSelector(actualUIState);
  const keepFiltersFlag = useSelector(GetKeepFiltersFlag);
  // times module admin
  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);
  const isTimeViewer = hasTimeViewerAccess(userRolesTimesModule);

  return {
    isInfoQueryLoaded,
    costCentersQuery,
    clientsQuery,
    projectsQuery,
    projectManagersQuery,
    usersQuery,
    countriesQuery,
    MetaTimesInfo,
    filters,
    isShowAdditionalFilters,
    initialStatefilters,
    isTimeAdmin,
    isTimeViewer,
    keepFiltersFlag
  };
};
