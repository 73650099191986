import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect, useState } from "react";
import Footer from "../../SharedModule/components/Footer";
import { Select } from "../../SharedModule/components/Select";
import { Calendar } from "../../SharedModule/components/Calendar";
import { useDispatch, useSelector } from "react-redux";
import { BiError, BiInfoCircle } from "react-icons/bi";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { Checkbox } from "../../SharedModule/components/Checkbox";
import {
  addElementToArray,
  checkDataIsLoaded,
  getProjectOrWorkOrderGroupedBy,
  sendNullIfEmpty,
} from "../utils/timesUtils";
import {
  ALL_PROJECTS_AND_WORK_ORDERS,
  LAST_MONTH,
  LAST_WEEK,
  ROUTE_QUERY_RESULTS_TIMESHEETS,
  ROUTE_QUERY_TIMESHEETS,
  THIS_MONTH,
  THIS_WEEK,
  TODAY,
} from "../../SharedModule/utils/constants";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  setFilters,
  setAdditionalFilters,
  keepFilters,
  setInfoQuery,
  selectClientsQuery,
  selectCostCentersQuery,
  selectCountriesQuery,
  selectProjectManagersQuery,
  selectProjectsQuery,
  selectUsersQuery,
} from "../redux/reducers/timesQuery.reducer";
import { Client, ProjectManagerOrUser, ProjectOrWo } from "../types";
import { useQueryPage } from "../hooks/useQueryPage";
import {
  actualUIState,
  setActiveTab,
} from "../../SharedModule/redux/reducers/uiShared.reducer";
import {
  getDateFromDateString,
  getIsoFormatStringWithTimeZone,
  getLastWeekDay,
  getLastWorkableDay,
  getPrevSunday,
  isStringDateValid,
  LAST_MONTHS_END,
  LAST_MONTHS_START,
  NOW,
  THIS_MONTHS_END,
  THIS_MONTHS_START,
} from "../../SharedModule/utils/dateUtils";
import { checkUrlParam } from "../../SharedModule/utils/headerUtils";
import { selectUserRoles } from "../../SharedModule/redux/reducers/auth.reducer";
import { TimesheetsService } from "../services/timesheets";
import eventHandler from "../../SharedModule/utils/eventHandler";
import { Spinner } from "../../SharedModule/components/Spinner";

export const TimesQueryPage = () => {
  const { trackPageView } = useMatomo();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeUserId } = useSelector(selectUserRoles);

  // times module admin
  const { isLoadingUserInfo } = useSelector(actualUIState);

  let [searchParams] = useSearchParams();

  const costCentersQueryInfo = useSelector(selectCostCentersQuery);
  const clientsQueryInfo = useSelector(selectClientsQuery);
  const projectsQueryInfo = useSelector(selectProjectsQuery);
  const projectManagersQueryInfo = useSelector(selectProjectManagersQuery);
  const usersQueryInfo = useSelector(selectUsersQuery);
  const countriesQueryInfo = useSelector(selectCountriesQuery);

  const {
    isInfoQueryLoaded,
    costCentersQuery,
    clientsQuery,
    projectsQuery,
    projectManagersQuery,
    usersQuery,
    countriesQuery,
    MetaTimesInfo,
    filters,
    isShowAdditionalFilters,
    initialStatefilters,
    isTimeAdmin,
    isTimeViewer,
    keepFiltersFlag,
  } = useQueryPage();

  // Local info to set filters at first load
  const [localInfoQuery, setLocalInfoQuery] = useState({
    costCentersQuery: costCentersQueryInfo,
    clientsQuery: clientsQueryInfo,
    projectsQuery: projectsQueryInfo,
    projectManagersQuery: projectManagersQueryInfo,
    usersQuery: usersQueryInfo,
    countriesQuery: countriesQueryInfo,
    nonBillableReasons: MetaTimesInfo.nonBillableReasons,
  });

  // call endpoint to have page filters
  const fetchQueryFiltersData = async () => {
    let filtersData,
      costCentersData = [];
    await TimesheetsService.getQueryFilters().then((response) => {
      filtersData = response.data;
    });
    if (filtersData?.data.costCenters) {
      addElementToArray(costCentersData, filtersData.data.costCenters);
    }

    return {
      costCentersQuery: costCentersData || null,
      clientsQuery:
        filtersData.data?.clients.map((elem: Client) => ({
          value: elem.id,
          label: elem.name,
        })) || null,
      projectsQuery:
        filtersData.data?.projects.map((elem: ProjectOrWo) => ({
          value: elem.entityId,
          label:
            elem.entityId > 0
              ? "Project " + elem.entityId + " - " + elem.entityName
              : elem.entityName,
          entityId: elem.entityId,
          categoryId: elem.categoryId,
          clientId: elem.clientId,
        })) || null,
      projectManagersQuery:
        filtersData.data?.managers.map((elem: ProjectManagerOrUser) => ({
          value: elem.id,
          label: elem.fullName,
          projectIds: elem.projectIds,
        })) || null,
      usersQuery:
        filtersData.data?.timeUsers.map((elem: ProjectManagerOrUser) => ({
          value: elem.id,
          label: elem.fullName,
          isActive: elem.isActive,
          email: elem.email,
        })) || null,
      countriesQuery:
        filtersData.data?.countries.map((elem: string) => ({
          value: elem,
          label: elem,
        })) || null,
    };
  };

  // ASUITE11-2613: Link to The Hub Query from the Summary - FE
  // use cache because metaTimesInfo could not be loaded after redirect from asuite
  let metaTaskTypesInCache: any = localStorage.getItem("metaTaskTypes");
  let metaBillingUsersInCache: any = localStorage.getItem("metaBillingUsers");

  let partialUserWithAsuiteId =
    metaBillingUsersInCache &&
    JSON.parse(metaBillingUsersInCache)?.find(
      (elem) => elem.asuiteId === Number(searchParams.get("userId"))
    );

  const showAdditionalFilterByParams =
    checkUrlParam(searchParams.get("taskType")) ||
    checkUrlParam(searchParams.get("viewBillable")) ||
    checkUrlParam(searchParams.get("viewNonBillable")) ||
    checkUrlParam(searchParams.get("viewFinanced"));

  const activeUsers = usersQuery?.filter((user) => user.isActive);

  // ASUITE11-2373: remove Benefits(8), Budget Exceeded(10), Unpaid Time Off(9) and ITX Investment: Internal Project(4) from the Non-Billable Reason dropdown
  const nonBillableReasonsAfterFilter =
    MetaTimesInfo.nonBillableReasons?.filter(
      (reason) => ![8, 10, 9, 4].includes(reason.value)
    );

  const [timeProjectsWithAllLogic, setTimeProjectsWithAllLogic] = useState<any>(
    getProjectOrWorkOrderGroupedBy(projectsQuery)
  );

  const setInitialUserInFilters = (structureData) => {
    // info from url with a value
    if (
      searchParams.get("userId") &&
      searchParams.get("userId") !== "-1" &&
      partialUserWithAsuiteId
    ) {
      return [
        structureData.usersQuery.find(
          (elem) => elem.value === partialUserWithAsuiteId.id
        ),
      ];
      // info from url without a value, should set filter as null
    } else if (
      searchParams.get("userId") &&
      searchParams.get("userId") === "-1"
    ) {
      return null;
      // if there is only on user in dropdown, should set it
    } else if (structureData.usersQuery?.length === 1) {
      return [structureData.usersQuery[0]];
      // if dont need to keep filters => is there is data in dropdown users ? find active user id : empty
    } else if (!keepFiltersFlag) {
      if (structureData.usersQuery) {
        return [
          structureData.usersQuery.find((user) => user.value === activeUserId),
        ];
      } else {
        return null;
      }
      // if need to keep filters
    } else {
      return filters.user;
    }
  };

  const setLocalFilters = (structureData) => {
    setPageFilters({
      startDate:
        checkUrlParam(searchParams.get("dateFrom")) &&
        isStringDateValid(searchParams.get("dateFrom") + "T00:00:00.000Z")
          ? searchParams.get("dateFrom") + "T00:00:00.000Z"
          : filters.startDate,
      endDate:
        checkUrlParam(searchParams.get("dateTo")) &&
        isStringDateValid(searchParams.get("dateTo") + "T00:00:00.000Z")
          ? searchParams.get("dateTo") + "T00:00:00.000Z"
          : filters.endDate,
      costCenter:
        structureData.costCentersQuery?.length === 1
          ? [structureData.costCentersQuery[0]]
          : filters.costCenter,
      client:
        structureData.clientsQuery?.length === 1
          ? [structureData.clientsQuery[0]]
          : filters.client,
      project: checkUrlParam(searchParams.get("projectId"))
        ? structureData.projectsQuery && [
            structureData.projectsQuery.find(
              (elem) => elem.entityId === Number(searchParams.get("projectId"))
            ),
          ]
        : filters.project,
      projectManager:
        structureData.projectManagersQuery?.length === 1
          ? [structureData.projectManagersQuery[0]]
          : filters.projectManager,
      user: setInitialUserInFilters(structureData),
      // aditional filters
      includeInactiveUsers: filters.includeInactiveUsers,
      taskType:
        checkUrlParam(searchParams.get("taskType")) && metaTaskTypesInCache
          ? [
              JSON.parse(metaTaskTypesInCache).find(
                (elem) => elem.label === searchParams.get("taskType")
              ),
            ]
          : MetaTimesInfo.taskTypes?.length === 1
          ? [MetaTimesInfo.taskTypes[0]]
          : filters.taskType,
      country:
        structureData.countriesQuery?.length === 1
          ? [structureData.countriesQuery[0]]
          : filters.country,
      hourType: {
        billableHours:
          checkUrlParam(searchParams.get("viewBillable")) &&
          !isNaN(Number(searchParams.get("viewBillable")))
            ? !!Number(searchParams.get("viewBillable"))
            : filters.hourType.billableHours,
        nonBillableHours:
          checkUrlParam(searchParams.get("viewNonBillable")) &&
          !isNaN(Number(searchParams.get("viewNonBillable")))
            ? !!Number(searchParams.get("viewNonBillable"))
            : filters.hourType.nonBillableHours,
        internalHours: filters.hourType.internalHours,
        financedHours:
          checkUrlParam(searchParams.get("viewFinanced")) &&
          !isNaN(Number(searchParams.get("viewFinanced")))
            ? !!Number(searchParams.get("viewFinanced"))
            : filters.hourType.financedHours,
      },
      nonBillableReasons: filters.nonBillableReasons,
      timeOff: {
        paidTimeOff: filters.timeOff.paidTimeOff,
        unpaidTimeOff: filters.timeOff.unpaidTimeOff,
      },
      approvalStatus: {
        pendingHours: filters.approvalStatus.pendingHours,
        approvedHours: filters.approvalStatus.approvedHours,
        lockedHours: filters.approvalStatus.lockedHours,
      },
      summary: {
        byUser: filters.summary.byUser,
        byTask: filters.summary.byTask,
        byTaskType: filters.summary.byTaskType,
        hideEntries: filters.summary.hideEntries,
      },
    });
  };

  const [pageFilters, setPageFilters] = useState({
    // Filters
    startDate: filters.startDate,
    endDate: filters.endDate,
    costCenter: filters.costCenter,
    client: filters.client,
    project: filters.project,
    projectManager: filters.projectManager,
    user: filters.user,
    // aditional filters
    includeInactiveUsers: filters.includeInactiveUsers,
    taskType: filters.taskType,
    country: filters.country,
    hourType: {
      billableHours: filters.hourType.billableHours,
      nonBillableHours: filters.hourType.nonBillableHours,
      internalHours: filters.hourType.internalHours,
      financedHours: filters.hourType.financedHours,
    },
    nonBillableReasons: filters.nonBillableReasons,
    timeOff: {
      paidTimeOff: filters.timeOff.paidTimeOff,
      unpaidTimeOff: filters.timeOff.unpaidTimeOff,
    },
    approvalStatus: {
      pendingHours: filters.approvalStatus.pendingHours,
      approvedHours: filters.approvalStatus.approvedHours,
      lockedHours: filters.approvalStatus.lockedHours,
    },
    summary: {
      byUser: filters.summary.byUser,
      byTask: filters.summary.byTask,
      byTaskType: filters.summary.byTaskType,
      hideEntries: filters.summary.hideEntries,
    },
  });

  useEffect(() => {
    document.title = "The Hub - Query";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    // should go in every times page
    if (!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin, isTimeViewer)) {
      eventHandler.dispatch("loadTimesStaticInfo", {
        isTimeAdmin: isTimeAdmin,
        isTimeViewer: isTimeViewer,
      });
    }

    const loadInfoQuery = async () => {
      let metaInfoQuery: any = await fetchQueryFiltersData();
      setLocalInfoQuery(metaInfoQuery);
      dispatch(setInfoQuery(metaInfoQuery));
      setLocalFilters(metaInfoQuery);
      setTimeProjectsWithAllLogic(
        getProjectOrWorkOrderGroupedBy(metaInfoQuery.projectsQuery)
      );
    };

    if (!isInfoQueryLoaded) {
      loadInfoQuery();
    } else {
      setLocalFilters(localInfoQuery);
    }

    dispatch(setActiveTab(ROUTE_QUERY_TIMESHEETS));

    dispatch(
      setAdditionalFilters(
        isShowAdditionalFilters || showAdditionalFilterByParams
      )
    );

    return () => {
      // reset filters if user got to another page
      if (window.location.pathname !== ROUTE_QUERY_RESULTS_TIMESHEETS) {
        dispatch(keepFilters(false));
        dispatch(setAdditionalFilters(false));
      }
    };
  }, [trackPageView, isTimeAdmin, isTimeViewer]);

  const today = new Date();
  // should use a huge objecy
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(
    isShowAdditionalFilters || showAdditionalFilterByParams
  );

  const updateDates = (option: string) => {
    switch (option) {
      case TODAY:
        setPageFilters({
          ...pageFilters,
          startDate: NOW.toISO({ includeOffset: false }),
          endDate: NOW.toISO({ includeOffset: false }),
        });
        break;
      case THIS_WEEK:
        setPageFilters({
          ...pageFilters,
          startDate: getIsoFormatStringWithTimeZone(getPrevSunday(today)),
          endDate: getIsoFormatStringWithTimeZone(getLastWorkableDay()),
        });
        break;
      case LAST_WEEK:
        setPageFilters({
          ...pageFilters,
          startDate: getIsoFormatStringWithTimeZone(getLastWeekDay(7, today)),
          endDate: getIsoFormatStringWithTimeZone(getLastWeekDay(1, today)),
        });
        break;
      case THIS_MONTH:
        setPageFilters({
          ...pageFilters,
          startDate: THIS_MONTHS_START.toISO({ includeOffset: false }),
          endDate: THIS_MONTHS_END.toISO({ includeOffset: false }),
        });
        break;
      case LAST_MONTH:
        setPageFilters({
          ...pageFilters,
          startDate: LAST_MONTHS_START.toISO({ includeOffset: false }),
          endDate: LAST_MONTHS_END.toISO({ includeOffset: false }),
        });
        break;
      default:
        break;
    }
  };

  const resetAditionalFilters = () => {
    setPageFilters({
      ...pageFilters,
      includeInactiveUsers: false,
      taskType:
        MetaTimesInfo.taskTypes?.length === 1
          ? [MetaTimesInfo.taskTypes[0]]
          : null,
      country: countriesQuery?.length === 1 ? [countriesQuery[0]] : null,
      hourType: {
        billableHours: true,
        nonBillableHours: true,
        internalHours: true,
        financedHours: true,
      },
      nonBillableReasons: null,
      timeOff: {
        paidTimeOff: true,
        unpaidTimeOff: false,
      },
      approvalStatus: {
        pendingHours: true,
        approvedHours: true,
        lockedHours: true,
      },
      summary: {
        byUser: false,
        byTask: false,
        byTaskType: false,
        hideEntries: false,
      },
    });
  };

  const toggleShowAdditionalsFilters = () => {
    if (!showAdditionalFilters) {
      resetAditionalFilters();
    }
    setShowAdditionalFilters(!showAdditionalFilters);
    dispatch(setAdditionalFilters(!showAdditionalFilters));
  };

  const resetFiltersPage = () => {
    setPageFilters(initialStatefilters);
  };

  const runQuery = () => {
    localStorage.setItem("queryFromQueryPage", "true");
    if (!showAdditionalFilters) {
      dispatch(
        setFilters({
          ...pageFilters,
          includeInactiveUsers: false,
          taskType: null,
          countrie: null,
          hourType: {
            billableHours: true,
            nonBillableHours: true,
            internalHours: true,
            financedHours: true,
          },
          nonBillableReasons: null,
          timeOff: {
            paidTimeOff: true,
            unpaidTimeOff: false,
          },
          approvalStatus: {
            pendingHours: true,
            approvedHours: true,
            lockedHours: true,
          },
          summary: {
            byUser: false,
            byTask: false,
            byTaskType: false,
            hideEntries: false,
          },
        })
      );
    } else {
      dispatch(setFilters(pageFilters));
    }
    navigate(ROUTE_QUERY_RESULTS_TIMESHEETS);
  };

  const handleProjectChange = (project: ProjectOrWo[]) => {
    let allProjects = project.find((elem) => elem.entityId === -2);
    let allWorkOrders = project.find((elem) => elem.entityId === -3);

    if (allProjects && allWorkOrders) {
      setTimeProjectsWithAllLogic(
        projectsQuery?.find((elem) => elem.entityId === 0)
      );
      let aux = project.filter((elem) => elem.entityId < 0);
      setPageFilters({ ...pageFilters, project: aux });
    } else if (allProjects) {
      setTimeProjectsWithAllLogic(
        getProjectOrWorkOrderGroupedBy(
          projectsQuery?.filter((elem) => elem.categoryId !== 1)
        )
      );
      let aux = project.filter((elem) => elem.categoryId !== 1);
      setPageFilters({ ...pageFilters, project: aux });
    } else if (allWorkOrders) {
      setTimeProjectsWithAllLogic(
        getProjectOrWorkOrderGroupedBy(
          projectsQuery?.filter((elem) => elem.categoryId !== 3)
        )
      );
      let aux = project.filter((elem) => elem.categoryId !== 3);
      setPageFilters({ ...pageFilters, project: aux });
    } else {
      setTimeProjectsWithAllLogic(
        getProjectOrWorkOrderGroupedBy(projectsQuery)
      );
      setPageFilters({ ...pageFilters, project: sendNullIfEmpty(project) });
    }
  };

  const handleChangeHourType = (option: number) => {
    // 1-billableHours, 2-nonBillableHours, 3-internalHours, 4-financedHours
    // At least one selected
    if (
      (option === 1 &&
        pageFilters.hourType.billableHours &&
        !pageFilters.hourType.nonBillableHours &&
        !pageFilters.hourType.internalHours &&
        !pageFilters.hourType.financedHours) ||
      (option === 2 &&
        !pageFilters.hourType.billableHours &&
        pageFilters.hourType.nonBillableHours &&
        !pageFilters.hourType.internalHours &&
        !pageFilters.hourType.financedHours) ||
      (option === 3 &&
        !pageFilters.hourType.billableHours &&
        !pageFilters.hourType.nonBillableHours &&
        pageFilters.hourType.internalHours &&
        !pageFilters.hourType.financedHours) ||
      (option === 4 &&
        !pageFilters.hourType.billableHours &&
        !pageFilters.hourType.nonBillableHours &&
        !pageFilters.hourType.internalHours &&
        pageFilters.hourType.financedHours)
    ) {
      return;
    }
    setPageFilters({
      ...pageFilters,
      hourType: {
        billableHours:
          option === 1
            ? !pageFilters.hourType.billableHours
            : pageFilters.hourType.billableHours,
        nonBillableHours:
          option === 2
            ? !pageFilters.hourType.nonBillableHours
            : pageFilters.hourType.nonBillableHours,
        internalHours:
          option === 3
            ? !pageFilters.hourType.internalHours
            : pageFilters.hourType.internalHours,
        financedHours:
          option === 4
            ? !pageFilters.hourType.financedHours
            : pageFilters.hourType.financedHours,
      },
      nonBillableReasons:
        option === 2
          ? !pageFilters.hourType.nonBillableHours
            ? pageFilters.nonBillableReasons
            : null
          : pageFilters.nonBillableReasons,
    });
  };

  const handleChangeApprovalStatus = (option: number) => {
    // 1-pendingHours, 2-approvedHours, 3-lockedHours
    // At least one selected
    if (
      (option === 1 &&
        pageFilters.approvalStatus.pendingHours &&
        !pageFilters.approvalStatus.approvedHours &&
        !pageFilters.approvalStatus.lockedHours) ||
      (option === 2 &&
        !pageFilters.approvalStatus.pendingHours &&
        pageFilters.approvalStatus.approvedHours &&
        !pageFilters.approvalStatus.lockedHours) ||
      (option === 3 &&
        !pageFilters.approvalStatus.pendingHours &&
        !pageFilters.approvalStatus.approvedHours &&
        pageFilters.approvalStatus.lockedHours)
    ) {
      return;
    }
    setPageFilters({
      ...pageFilters,
      approvalStatus: {
        pendingHours:
          option === 1
            ? !pageFilters.approvalStatus.pendingHours
            : pageFilters.approvalStatus.pendingHours,
        approvedHours:
          option === 2
            ? !pageFilters.approvalStatus.approvedHours
            : pageFilters.approvalStatus.approvedHours,
        lockedHours:
          option === 3
            ? !pageFilters.approvalStatus.lockedHours
            : pageFilters.approvalStatus.lockedHours,
      },
    });
  };

  const getMinDate = (): any => {
    return getDateFromDateString(pageFilters.startDate);
  };

  const handleChangeSummary = (option: number) => {
    // 1-byUser, 2-byTask, 3-byTaskType 4-Hide ind entries
    // At least one selected when hide is selected
    if (
      (option === 1 &&
        pageFilters.summary.byUser &&
        !pageFilters.summary.byTask &&
        !pageFilters.summary.byTaskType &&
        pageFilters.summary.hideEntries) ||
      (option === 2 &&
        !pageFilters.summary.byUser &&
        pageFilters.summary.byTask &&
        !pageFilters.summary.byTaskType &&
        pageFilters.summary.hideEntries) ||
      (option === 3 &&
        !pageFilters.summary.byUser &&
        !pageFilters.summary.byTask &&
        pageFilters.summary.byTaskType &&
        pageFilters.summary.hideEntries)
    ) {
      return;
    }
    setPageFilters({
      ...pageFilters,
      summary: {
        byUser:
          option === 1
            ? !pageFilters.summary.byUser
            : pageFilters.summary.byUser,
        byTask:
          option === 2
            ? !pageFilters.summary.byTask
            : pageFilters.summary.byTask,
        byTaskType:
          option === 3
            ? !pageFilters.summary.byTaskType
            : pageFilters.summary.byTaskType,
        hideEntries:
          option === 4
            ? !pageFilters.summary.hideEntries
            : pageFilters.summary.hideEntries,
      },
    });
  };

  return (
    <>
      {(!isInfoQueryLoaded ||
        isLoadingUserInfo ||
        !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin, isTimeViewer)) && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {isInfoQueryLoaded &&
        !isLoadingUserInfo &&
        checkDataIsLoaded(MetaTimesInfo, isTimeAdmin, isTimeViewer) && (
          <div className="content">
            <div className="d-flex flex-wrap">
              <h2 className="flex-fill">Query</h2>
              <div className="mt-3">
                If you are looking for time entries older than 2024, use the{" "}
                <Link
                  to={`${process.env.REACT_APP_ASUITE_BASE_URI}/Timesheets/timeview.asp?qry=NewQuery`}
                  className="link-text text-decoration-none"
                >
                  ASuite Query
                </Link>
              </div>
            </div>
            <form className="card entry-hour-box-background p-4 mt-3">
              <div className="row justify-content-center">
                <label className="col-sm-3 col-form-label">Time Frame</label>
                <div className="col-sm-5">
                  <div className="d-flex">
                    <div className="position-relative">
                      <label className="form-label">From</label>
                      <Calendar
                        date={pageFilters.startDate}
                        onChange={(date: string) =>
                          setPageFilters({ ...pageFilters, startDate: date })
                        }
                      />
                    </div>
                    <div className="position-relative ms-5">
                      <label className="form-label">To</label>
                      <Calendar
                        date={pageFilters.endDate}
                        onChange={(date: string) =>
                          setPageFilters({ ...pageFilters, endDate: date })
                        }
                        className={`${
                          new Date(pageFilters.startDate) >
                          new Date(pageFilters.endDate)
                            ? "border-error"
                            : ""
                        }`}
                        minDate={getMinDate()}
                      />
                    </div>
                  </div>
                  {new Date(pageFilters.startDate) >
                    new Date(pageFilters.endDate) && (
                    <div className="error-message my-2">
                      <BiError className="error-icon" />
                      <span className="ms-1">
                        The To date cannot be lower than the From date.
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="mt-2 offset-sm-5 col-sm-7">
                  <div
                    className="btn button-secondary"
                    onClick={() => updateDates(TODAY)}
                  >
                    {TODAY}
                  </div>
                  <div
                    className="btn button-secondary ms-2"
                    onClick={() => updateDates(THIS_WEEK)}
                  >
                    {THIS_WEEK}
                  </div>
                  <div
                    className="btn button-secondary ms-2"
                    onClick={() => updateDates(LAST_WEEK)}
                  >
                    {LAST_WEEK}
                  </div>
                  <div
                    className="btn button-secondary ms-2"
                    onClick={() => updateDates(THIS_MONTH)}
                  >
                    {THIS_MONTH}
                  </div>
                  <div
                    className="btn button-secondary ms-2"
                    onClick={() => updateDates(LAST_MONTH)}
                  >
                    {LAST_MONTH}
                  </div>
                </div>
              </div>

              <div className="row mt-4 justify-content-center">
                <label className="col-sm-3 col-form-label">
                  Cost Center
                  <Tooltip
                    title={
                      <b>
                        Time for users that are assigned to this Cost Center
                      </b>
                    }
                    placement="right"
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <span>
                      <BiInfoCircle className="disc-icon ms-1" />
                    </span>
                  </Tooltip>
                </label>
                <div className="col-sm-5">
                  <Select
                    isMulti
                    placeholder="All"
                    options={costCentersQuery}
                    value={pageFilters.costCenter}
                    onChange={(costCenter) =>
                      setPageFilters({
                        ...pageFilters,
                        costCenter: sendNullIfEmpty(costCenter),
                      })
                    }
                    isDisabled={
                      isLoadingUserInfo ||
                      !checkDataIsLoaded(
                        MetaTimesInfo,
                        isTimeAdmin,
                        isTimeViewer
                      )
                    }
                  />
                </div>
              </div>

              <div className="row mt-3 justify-content-center">
                <label className="col-sm-3 col-form-label">Client</label>
                <div className="col-sm-5">
                  <Select
                    isMulti
                    placeholder="All"
                    options={clientsQuery}
                    value={pageFilters.client}
                    onChange={(client) =>
                      setPageFilters({
                        ...pageFilters,
                        client: sendNullIfEmpty(client),
                      })
                    }
                    isDisabled={
                      isLoadingUserInfo ||
                      !checkDataIsLoaded(
                        MetaTimesInfo,
                        isTimeAdmin,
                        isTimeViewer
                      )
                    }
                  />
                </div>
              </div>

              <div className="row mt-3 justify-content-center">
                <label className="col-sm-3 col-form-label">Project</label>
                <div className="col-sm-5">
                  <Select
                    isMulti
                    placeholder={ALL_PROJECTS_AND_WORK_ORDERS}
                    options={timeProjectsWithAllLogic}
                    value={pageFilters.project}
                    onChange={(project) => handleProjectChange(project)}
                    isDisabled={
                      isLoadingUserInfo ||
                      !checkDataIsLoaded(
                        MetaTimesInfo,
                        isTimeAdmin,
                        isTimeViewer
                      )
                    }
                  />
                </div>
              </div>

              <div className="row mt-3 justify-content-center">
                <label className="col-sm-3 col-form-label">
                  Project Manager
                  <Tooltip
                    title={
                      <b>
                        Time in projects that are assigned to this Project
                        Manager
                      </b>
                    }
                    placement="right"
                    TransitionComponent={Zoom}
                    arrow
                  >
                    <span>
                      <BiInfoCircle className="disc-icon ms-1" />
                    </span>
                  </Tooltip>
                </label>
                <div className="col-sm-5">
                  <Select
                    isMulti
                    placeholder="All"
                    options={projectManagersQuery}
                    value={pageFilters.projectManager}
                    onChange={(manager) =>
                      setPageFilters({
                        ...pageFilters,
                        projectManager: sendNullIfEmpty(manager),
                      })
                    }
                    isDisabled={
                      isLoadingUserInfo ||
                      !checkDataIsLoaded(
                        MetaTimesInfo,
                        isTimeAdmin,
                        isTimeViewer
                      )
                    }
                  />
                </div>
              </div>

              <div className="row mt-3 justify-content-center">
                <label className="col-sm-3 col-form-label">User</label>
                <div className="col-sm-5">
                  <Select
                    isMulti
                    placeholder="All"
                    options={
                      pageFilters.includeInactiveUsers
                        ? usersQuery
                        : activeUsers
                    }
                    value={pageFilters.user}
                    onChange={(user) =>
                      setPageFilters({
                        ...pageFilters,
                        user: sendNullIfEmpty(user),
                      })
                    }
                    isDisabled={
                      isLoadingUserInfo ||
                      !checkDataIsLoaded(
                        MetaTimesInfo,
                        isTimeAdmin,
                        isTimeViewer
                      )
                    }
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-sm-5 offset-sm-5">
                  <div
                    className="link-text px-0"
                    onClick={() => toggleShowAdditionalsFilters()}
                  >
                    {showAdditionalFilters ? "Hide" : "Show"} additional filter
                    options
                    {showAdditionalFilters ? " (reset filters and close)" : ""}
                  </div>
                </div>
              </div>

              {showAdditionalFilters && (
                <>
                  {isTimeAdmin && (
                    <div className="row justify-content-center mt-4">
                      <label className="col-sm-3 col-form-label"></label>
                      <div className="col-sm-5">
                        <Checkbox
                          label="Include inactive users"
                          value={pageFilters.includeInactiveUsers}
                          onClickHandler={() =>
                            setPageFilters({
                              ...pageFilters,
                              includeInactiveUsers:
                                !pageFilters.includeInactiveUsers,
                            })
                          }
                        />
                      </div>
                    </div>
                  )}

                  <div className="row mt-4 justify-content-center">
                    <label className="col-sm-3 col-form-label">Task Type</label>
                    <div className="col-sm-5">
                      <Select
                        isMulti
                        placeholder="All"
                        options={MetaTimesInfo.taskTypes}
                        value={pageFilters.taskType}
                        onChange={(taskType) =>
                          setPageFilters({
                            ...pageFilters,
                            taskType: sendNullIfEmpty(taskType),
                          })
                        }
                        isDisabled={
                          isLoadingUserInfo ||
                          !checkDataIsLoaded(
                            MetaTimesInfo,
                            isTimeAdmin,
                            isTimeViewer
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="row mt-3 justify-content-center">
                    <label className="col-sm-3 col-form-label">
                      Country
                      <Tooltip
                        title={
                          <b>Time for users that are located in this Country</b>
                        }
                        placement="right"
                        TransitionComponent={Zoom}
                        arrow
                      >
                        <span>
                          <BiInfoCircle className="disc-icon ms-1" />
                        </span>
                      </Tooltip>
                    </label>
                    <div className="col-sm-5">
                      <Select
                        isMulti
                        placeholder="All"
                        options={countriesQuery}
                        value={pageFilters.country}
                        onChange={(country) =>
                          setPageFilters({
                            ...pageFilters,
                            country: sendNullIfEmpty(country),
                          })
                        }
                        isDisabled={
                          isLoadingUserInfo ||
                          !checkDataIsLoaded(
                            MetaTimesInfo,
                            isTimeAdmin,
                            isTimeViewer
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="row justify-content-center mt-4">
                    <label className="col-sm-3 col-form-label">Hour Type</label>
                    <div className="col-sm-5">
                      <div className="d-flex">
                        <Checkbox
                          label="Billable hours"
                          value={pageFilters.hourType.billableHours}
                          onClickHandler={() => handleChangeHourType(1)}
                        />
                        <Tooltip
                          title={
                            <b>
                              Billable Hours: Time that the client pays for, on
                              Client projects
                            </b>
                          }
                          placement="right"
                          TransitionComponent={Zoom}
                          arrow
                        >
                          <span>
                            <BiInfoCircle className="disc-icon ms-2" />
                          </span>
                        </Tooltip>
                      </div>
                      <div className="d-flex">
                        <Checkbox
                          className="mt-2"
                          label="Financed hours"
                          value={pageFilters.hourType.financedHours}
                          onClickHandler={() => handleChangeHourType(4)}
                        />
                        <Tooltip
                          title={
                            <b>
                              Financed Hours: Time that ITX pays for, on Client
                              and Internal projects
                            </b>
                          }
                          placement="right"
                          TransitionComponent={Zoom}
                          arrow
                        >
                          <span>
                            <BiInfoCircle className="disc-icon ms-2 mt-2" />
                          </span>
                        </Tooltip>
                      </div>
                      <div className="d-flex">
                        <Checkbox
                          className="mt-2"
                          label="Non-Billable hours"
                          value={pageFilters.hourType.nonBillableHours}
                          onClickHandler={() => handleChangeHourType(2)}
                        />
                        <Tooltip
                          title={
                            <b>
                              Non-Billable Hours: Time that is not paid for, on
                              Client projects
                            </b>
                          }
                          placement="right"
                          TransitionComponent={Zoom}
                          arrow
                        >
                          <span>
                            <BiInfoCircle className="disc-icon ms-2 mt-2" />
                          </span>
                        </Tooltip>
                      </div>
                      <div className="d-flex">
                        <Checkbox
                          className="mt-2"
                          label="Internal hours"
                          value={pageFilters.hourType.internalHours}
                          onClickHandler={() => handleChangeHourType(3)}
                        />
                        <Tooltip
                          title={
                            <b>
                              Internal Hours: Non-Billable time, on Internal
                              projects
                            </b>
                          }
                          placement="right"
                          TransitionComponent={Zoom}
                          arrow
                        >
                          <span>
                            <BiInfoCircle className="disc-icon ms-2 mt-2" />
                          </span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 justify-content-center">
                    <label className="col-sm-3 col-form-label">
                      Non-Billable Reason
                    </label>
                    <div className="col-sm-5">
                      <Select
                        isMulti
                        placeholder="All"
                        options={nonBillableReasonsAfterFilter}
                        value={pageFilters.nonBillableReasons}
                        onChange={(nonBillableReason) =>
                          setPageFilters({
                            ...pageFilters,
                            nonBillableReasons:
                              sendNullIfEmpty(nonBillableReason),
                          })
                        }
                        isDisabled={
                          !pageFilters.hourType.nonBillableHours ||
                          isLoadingUserInfo ||
                          !checkDataIsLoaded(
                            MetaTimesInfo,
                            isTimeAdmin,
                            isTimeViewer
                          )
                        }
                      />
                    </div>
                  </div>

                  <div className="row justify-content-center mt-4">
                    <label className="col-sm-3 col-form-label">Time Off</label>
                    <div className="col-sm-5">
                      <Checkbox
                        label="Paid time off"
                        value={pageFilters.timeOff.paidTimeOff}
                        onClickHandler={() =>
                          setPageFilters({
                            ...pageFilters,
                            timeOff: {
                              paidTimeOff: !pageFilters.timeOff.paidTimeOff,
                              unpaidTimeOff: pageFilters.timeOff.unpaidTimeOff,
                            },
                          })
                        }
                      />
                      <Checkbox
                        className="mt-2"
                        label="Unpaid time off"
                        value={pageFilters.timeOff.unpaidTimeOff}
                        onClickHandler={() =>
                          setPageFilters({
                            ...pageFilters,
                            timeOff: {
                              paidTimeOff: pageFilters.timeOff.paidTimeOff,
                              unpaidTimeOff: !pageFilters.timeOff.unpaidTimeOff,
                            },
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="row justify-content-center mt-4">
                    <label className="col-sm-3 col-form-label">
                      Approval Status
                    </label>
                    <div className="col-sm-5">
                      <Checkbox
                        label="Pending hours"
                        value={pageFilters.approvalStatus.pendingHours}
                        onClickHandler={() => handleChangeApprovalStatus(1)}
                      />
                      <Checkbox
                        className="mt-2"
                        label="Approved hours"
                        value={pageFilters.approvalStatus.approvedHours}
                        onClickHandler={() => handleChangeApprovalStatus(2)}
                      />
                      <Checkbox
                        className="mt-2"
                        label="Locked hours"
                        value={pageFilters.approvalStatus.lockedHours}
                        onClickHandler={() => handleChangeApprovalStatus(3)}
                      />
                    </div>
                  </div>

                  <div className="row justify-content-center mt-4">
                    <label className="col-sm-3 col-form-label">
                      Optional Summaries
                    </label>
                    <div className="col-sm-5">
                      <Checkbox
                        label="Summary by User"
                        value={pageFilters.summary.byUser}
                        onClickHandler={() => handleChangeSummary(1)}
                      />
                      <Checkbox
                        className="mt-2"
                        label="Summary by Task"
                        value={pageFilters.summary.byTask}
                        onClickHandler={() => handleChangeSummary(2)}
                      />
                      <Checkbox
                        className="mt-2"
                        label="Summary by Task Type"
                        value={pageFilters.summary.byTaskType}
                        onClickHandler={() => handleChangeSummary(3)}
                      />
                      <Checkbox
                        className="mt-2"
                        label="Hide individual entries"
                        value={pageFilters.summary.hideEntries}
                        onClickHandler={() => handleChangeSummary(4)}
                        isDisabled={
                          (!pageFilters.summary.byUser &&
                            !pageFilters.summary.byTask &&
                            !pageFilters.summary.byTaskType) ||
                          isLoadingUserInfo ||
                          !checkDataIsLoaded(
                            MetaTimesInfo,
                            isTimeAdmin,
                            isTimeViewer
                          )
                        }
                      />
                    </div>
                  </div>
                </>
              )}

              <hr className="separator" />
              <div className="mt-4 footer-filter">
                <div
                  className="link-text"
                  onClick={() => {
                    resetFiltersPage();
                  }}
                >
                  Reset Filters
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => runQuery()}
                  disabled={
                    new Date(pageFilters.startDate) >
                    new Date(pageFilters.endDate)
                  }
                >
                  Run Query
                </button>
              </div>
            </form>
            <Footer />
          </div>
        )}
    </>
  );
};
