import { useState, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { BiAlarm, BiCaretDown, BiLogOut, BiLogIn } from "react-icons/bi";
import logo from "./../../styles/legacy/hub-logo.svg";
import logoRelease from "./../../styles/legacy/PREPROD.svg";
import logoStg from "./../../styles/legacy/STAGING.svg";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { TabLabel } from "./../../BillingModule/components/TabLabel";
import {
  ROUTE_INVOICES_LIST,
  ROUTE_PRODUCTS_LIST,
  ROUTE_THEHUB_ADMIN,
  ROUTE_LOG_OUT,
  DEVELOPMENT,
  RELEASE,
  PRODUCTION,
  THIS_WEEK,
  ROUTE_HOME,
  ROUTE_TIMESHEETS,
  ADMIN_ACCESS,
  TODAY,
} from "../utils/constants";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { AccessControl } from "./AccessControl";
import eventHandler from "../utils/eventHandler";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, selectUserRoles } from "../redux/reducers/auth.reducer";
import { useTopNavigationInfo } from "../hooks/useTopNavigationInfo";
import { quantityFormat } from "../utils/formatters";
import {
  actualUIState,
  clear,
  toggleLeftNavBarFixed,
} from "../../SharedModule/redux/reducers/uiShared.reducer";
import { getInitials } from "../../ProductsModule/utils/keyContactUtils";
import { checkEnvironment, hasModuleAccess } from "../utils/headerUtils";
import { UserLink } from "../../TimesModule/types";
import { changeListView } from "../../TimesModule/redux/reducers/times.reducer";
import {
  hasSuperAdminAccess,
  isOnlyRecurringBillingAdminRole,
} from "../utils/accessUtils";

export default function Header() {
  const [userModalOpen, setUserModalOpen] = useState(false);
  const dropdownRef: any = useRef();

  const { isLeftNavBarFixed } = useSelector(actualUIState);

  let environment = checkEnvironment();

  const imgRef: any = useRef();
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(dropdownRef, (event) => {
    if (userModalOpen && event.target !== imgRef.current) {
      setUserModalOpen(false);
    }
  });

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const auth = useSelector(selectAuth);

  const { todayHours, weeklyHours, photo, links, modulePermissions } =
    useTopNavigationInfo();

  const { activeUserName, userRoles } = useSelector(selectUserRoles);

  const isSuperAdmin = hasSuperAdminAccess(userRoles);
  const isOnlyRecurringBillingAdmin =
    isOnlyRecurringBillingAdminRole(userRoles);

  eventHandler.on("logout", () => {
    setUserModalOpen(false);
  });

  const checkActiveTab = (tab: string) => {
    return location.pathname.startsWith(tab);
  };

  const logOut = () => {
    if (isLeftNavBarFixed) {
      dispatch(toggleLeftNavBarFixed());
    }
    localStorage.setItem("lastPageVisited", location.pathname);
    setUserModalOpen((state) => !state);
    dispatch(clear());
    navigate(ROUTE_LOG_OUT, { replace: true });
  };

  const LogInHeader = () => {
    eventHandler.dispatch("authLogin", {});
    navigate(ROUTE_HOME);
  };

  const checkModal = () => {
    setUserModalOpen(!userModalOpen);
  };

  const goToHomePage = () => {
    if (!auth.isAuthenticated) {
      eventHandler.dispatch("authLogin", {});
    }
    if (isLeftNavBarFixed) {
      dispatch(toggleLeftNavBarFixed());
    }
  };

  const clickHours = (isToday: boolean) => {
    dispatch(changeListView(isToday));
    if (location.pathname === ROUTE_TIMESHEETS) {
      eventHandler.dispatch("changeToSheetView", { isSheetView: !isToday });
    }
  };

  const getBillingLinkByRole = () => {
    const asuiteAdminRecurringBillingRoute: any =
      process.env.REACT_APP_ASUITE_ADMIN_BILLING_RECURRING;
    if (isSuperAdmin || !isOnlyRecurringBillingAdmin) {
      return ROUTE_INVOICES_LIST;
    } else {
      return asuiteAdminRecurringBillingRoute;
    }
  };

  return (
    <div className="header d-flex justify-content-between">
      <div className="d-flex">
        <Link
          to={ROUTE_HOME}
          className="d-flex no-link pointer"
          onClick={() => goToHomePage()}
          style={{ width: "min-content" }}
        >
          <img src={logo} className="header-logo" alt="ITX Logo" />
          {environment === DEVELOPMENT && (
            <img src={logoStg} className="header-logo-env" alt="ITX Logo" />
          )}
          {environment === RELEASE && (
            <img src={logoRelease} className="header-logo-env" alt="ITX Logo" />
          )}
          {environment === PRODUCTION && (
            <div className="header-logo-env"></div>
          )}
        </Link>
        {auth.isAuthenticated && (
          <div className="d-flex flex-row" style={{ marginLeft: "1rem" }}>
            {(hasModuleAccess("Time", modulePermissions) || isSuperAdmin) && (
              <Link
                className="text-decoration-none my-auto"
                to={ROUTE_TIMESHEETS}
              >
                <TabLabel
                  key="timesheets"
                  active={checkActiveTab(ROUTE_TIMESHEETS)}
                  classes="top-nav-tab"
                  onClick={() => null}
                >
                  <div className="header-link">Time</div>
                </TabLabel>
              </Link>
            )}
            <Link
              className="text-decoration-none my-auto"
              to={ROUTE_PRODUCTS_LIST}
            >
              <TabLabel
                key="products"
                active={checkActiveTab(ROUTE_PRODUCTS_LIST)}
                classes="top-nav-tab"
                onClick={() => null}
              >
                <div className="header-link">Products</div>
              </TabLabel>
            </Link>
            {(hasModuleAccess("Projects", modulePermissions) ||
              isSuperAdmin) && (
              <Link
                className="text-decoration-none my-auto"
                to={
                  process.env.REACT_APP_ASUITE_PROJECTS_LINK
                    ? process.env.REACT_APP_ASUITE_PROJECTS_LINK
                    : ""
                }
              >
                <TabLabel
                  key="projects"
                  classes="top-nav-tab"
                  onClick={() => null}
                >
                  <div className="header-link">Projects</div>
                </TabLabel>
              </Link>
            )}
            {(hasModuleAccess("Work Orders", modulePermissions) ||
              isSuperAdmin) && (
              <Link
                className="text-decoration-none my-auto"
                to={
                  process.env.REACT_APP_ASUITE_WO_LINK
                    ? process.env.REACT_APP_ASUITE_WO_LINK
                    : ""
                }
              >
                <TabLabel
                  key="work-orders"
                  classes="top-nav-tab"
                  onClick={() => null}
                >
                  <div className="header-link">Work Orders</div>
                </TabLabel>
              </Link>
            )}
            {(hasModuleAccess("Bus Dev", modulePermissions) ||
              isSuperAdmin) && (
              <Link
                className="text-decoration-none my-auto"
                to={
                  process.env.REACT_APP_ASUITE_BUS_DEV_LINK
                    ? process.env.REACT_APP_ASUITE_BUS_DEV_LINK
                    : ""
                }
              >
                <TabLabel
                  key="bus-dev"
                  classes="top-nav-tab"
                  onClick={() => null}
                >
                  <div className="header-link">Bus Dev</div>
                </TabLabel>
              </Link>
            )}
            {(hasModuleAccess("Intelligence", modulePermissions) ||
              isSuperAdmin) && (
              <Link
                className="text-decoration-none my-auto"
                to={
                  process.env.REACT_APP_ASUITE_CLI_INT_LINK
                    ? process.env.REACT_APP_ASUITE_CLI_INT_LINK
                    : ""
                }
              >
                <TabLabel
                  key="intelligence"
                  classes="top-nav-tab"
                  onClick={() => null}
                >
                  <div className="header-link">Intelligence</div>
                </TabLabel>
              </Link>
            )}
            {(hasModuleAccess("Billing", modulePermissions) ||
              isSuperAdmin ||
              isOnlyRecurringBillingAdmin) && (
              <Link
                className="text-decoration-none my-auto"
                to={getBillingLinkByRole()}
              >
                <TabLabel
                  key="Billing"
                  active={checkActiveTab(ROUTE_INVOICES_LIST)}
                  classes="top-nav-tab"
                  onClick={() => null}
                >
                  <div className="header-link">Billing</div>
                </TabLabel>
              </Link>
            )}
            <AccessControl allowedRoles={ADMIN_ACCESS}>
              <Link
                className="text-decoration-none my-auto"
                to={ROUTE_THEHUB_ADMIN}
              >
                <TabLabel
                  key="admin"
                  active={checkActiveTab(ROUTE_THEHUB_ADMIN)}
                  classes="top-nav-tab"
                  onClick={() => null}
                >
                  <div className="header-link">Admin</div>
                </TabLabel>
              </Link>
            </AccessControl>
          </div>
        )}
      </div>
      {!auth.isAuthenticated && (
        <div className="tertiary-button pointer" onClick={() => LogInHeader()}>
          Log in
        </div>
      )}
      {auth.isAuthenticated && (
        <div className="d-flex flex-row me-3">
          <Link to={ROUTE_TIMESHEETS} className="header-icon">
            <BiAlarm className="icon" />
          </Link>
          <div className="d-flex flex-column ms-2">
            <Tooltip
              title={<>{"Hours Worked"}</>}
              placement="bottom"
              TransitionComponent={Zoom}
              arrow
            >
              <span className="header-title-hours">Hours Worked</span>
            </Tooltip>
            <span className="header-hours" style={{ marginRight: "0.5rem" }}>
              <Tooltip
                title={<b>{TODAY}</b>}
                placement="bottom"
                TransitionComponent={Zoom}
                arrow
              >
                <Link
                  to={ROUTE_TIMESHEETS}
                  className="link-hours"
                  onClick={() => clickHours(true)}
                >
                  {auth.isAuthenticated ? quantityFormat(todayHours) : "00.00"}
                </Link>
              </Tooltip>{" "}
              /{" "}
              <Tooltip
                title={<b>{THIS_WEEK}</b>}
                placement="bottom"
                TransitionComponent={Zoom}
                arrow
              >
                <Link
                  to={ROUTE_TIMESHEETS}
                  className="link-hours"
                  onClick={() => clickHours(false)}
                >
                  {auth.isAuthenticated ? quantityFormat(weeklyHours) : "00.00"}
                </Link>
              </Tooltip>
            </span>
          </div>
          {photo === undefined && (
            <div className="ms-3 user-container">
              <div className="user-initials grey"></div>
            </div>
          )}
          {(photo === null || photo) && (
            <div className="ms-3 user-container" onClick={() => checkModal()}>
              {photo === null && (
                <div className="user-initials">
                  {getInitials(`${activeUserName}`)}
                </div>
              )}
              {photo && (
                <img
                  src={`data:image/jpeg;base64,${photo}`}
                  className="user-image"
                  alt="user"
                  ref={imgRef}
                />
              )}
              <BiCaretDown className="user-arrow" />
            </div>
          )}
          <div
            className={`dropdown-menu user-modal ${
              userModalOpen ? "show" : ""
            }`}
            ref={dropdownRef}
          >
            <div className="user-modal-link d-flex justify-content-between">
              <span>My Links</span>
              <Link
                to={
                  process.env.REACT_APP_ASUITE_BASE_URI + "/profile/mylinks.asp"
                }
                className="user-modal-link-purple text-decoration-none"
              >
                Manage
              </Link>
            </div>
            <hr />
            {(!links || links.length === 0) && (
              <div className="user-modal-no-links">You have no quick links</div>
            )}
            {links &&
              links.length > 0 &&
              links.map((link: UserLink) => {
                return (
                  <a
                    key={link.id}
                    className="user-modal-link back-purple text-decoration-none d-flex"
                    href={link.location}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.title}
                  </a>
                );
              })}
            <hr />
            {auth.isAuthenticated && (
              <div
                className="user-modal-link user-log-out"
                onClick={() => logOut()}
              >
                <BiLogOut className="icon-log-out" />
                <span className="ms-1">Log Out</span>
              </div>
            )}
            {!auth.isAuthenticated && (
              <div
                className="user-modal-link user-log-out"
                onClick={() => LogInHeader()}
              >
                <BiLogIn className="icon-log-out" />
                <span className="ms-1">Log In</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
